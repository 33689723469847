<template lang="pug">
include ../../configs/template
div(v-if="checkAccess('backOffice') && !isLoading.page").row
  div.col-sm-12.col-md-4
    // functional change 2fa access for user
    //- div.row
    //-   div.col-sm-12.text-left
    //-     +switch('body.is_totp_enabled', 'turn_on', 'turn_off', 'twoFA')(:loading="isLoading.is_totp_enabled" @change="change2FaAuth('is_totp_enabled')")
    //-   div.col-sm-12.text-left
    //-     +switch('body.is_u2f_enabled', 'turn_on', 'turn_off', 'Ubikey')(:loading="isLoading.is_u2f_enabled" @change="change2FaAuth('is_u2f_enabled')")
  v-divider(vertical).d-sm-none.d-md-block.d-lg-block.d-xl-block
  div.col-sm-12.col-md-8
    div.d-flex.justify-spacce-between.align-center
      v-btn(text outlined color="orange" @click="registrationUbikey")
        v-icon(color="orange").mr-2 mdi-key-wireless
        span {{ $t('btn.addedUbikey') }}
    v-divider(v-if="registrationUbikey.length || listDevice.length")
    div.row
      div.col-sm-12.col-md-6
        ListAccountTOTP(:listDevice="listDevice")
      v-divider(v-if="listDeviceUbikey.length" vertical).d-sm-none.d-md-block.d-lg-block.d-xl-block
      div.col-sm-12.col-md-6
        ListAccountUbikey(:listDevice="listDeviceUbikey")
v-progress-linear(v-else-if="isLoading.page" indeterminate)

</template>

<script>
import { checkAccess } from '@/mixins/permissions'
import { mapActions, mapState } from 'vuex'
import { SUCCESS_CODE } from '@/configs/constants'
export default {
  name: 'AccountSettings',
  components: {
    ListAccountTOTP: () => import('./ListAccountTOTP'),
    ListAccountUbikey: () => import('./ListAccountUbikey')
  },
  props: {
    accountData: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      checkAccess,
      body: {
        is_totp_enabled: null,
        is_u2f_enabled: null
      },
      isLoading: {
        is_totp_enabled: false,
        is_u2f_enabled: false,
        page: true
      }
    }
  },
  computed: {
    ...mapState({
      listDevice: state => state.user.listDevice,
      listDeviceUbikey: state => state.user.listDeviceUbikey
    })
  },
  mounted () {
    Object.keys(this.body).forEach(key => (this.body[key] = this.accountData[key]))
    this.getData()
  },
  methods: {
    ...mapActions(['getListDeviceWithTOTP', 'updateUser', 'createForUserFido2', 'getListDeviceWithUbikey']),
    async getData () {
      this.isLoading.page = true
      await this.getListDeviceWithTOTP({ user_pk: this.$route.params.documentID })
      await this.getListDeviceWithUbikey({ user_pk: this.$route.params.documentID })
      this.isLoading.page = false
    },
    change2FaAuth (key) {
      this.isLoading[key] = true
      this.$swal({
        icon: 'warning',
        title: this.$t('notify.title.warning'),
        text: this.$t('notify.warning.Do you sure that you want 2fa', {
          state: this.body[key] ? this.$t('turnOn') : this.$t('turnOff'),
          type: key === 'is_totp_enabled' ? 'Google Authentificator' : 'Ubikey'

        }),
        buttons: [this.$t('no'), this.$t('yes')]
      }).then(async confirm => {
        if (confirm) {
          const response = await this.updateUser({ ...this.$route.params, body: this.body })
          if (response) this.$notification.success('notify.success.changedTypeAuthorization')
        } else this.body[key] = !this.body[key]
      }).finally(() => (this.isLoading[key] = false))
    },
    async registrationUbikey () {
      const response = await this.createForUserFido2({ userId: this.$route.params.documentID })
      if (SUCCESS_CODE.includes(response.code)) {
        this.$notification.success('notify.success.addedUbikey')
        this.getListDeviceWithUbikey({ user_pk: this.$route.params.documentID })
      }
    }
  }
}
</script>
